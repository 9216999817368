import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import color from '@/constants/color'

export const css3rdeconomyColor = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: ${color.white1};
  letter-spacing: 1px;
  background: linear-gradient(100deg, ${color.green2}, ${color.blue8});
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssLink = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background-image: linear-gradient(100deg, ${color.orange1}, ${color.orange2});
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssGradientButton = css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  color: ${color.white1};
  letter-spacing: 1px;
  background-image: linear-gradient(100deg, ${color.blue1}, ${color.blue2});
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`

export const cssShadowButton = css`
  box-shadow: 0 6px 8px ${color.black4};
`

export const cssNewsLink = css`
  z-index: 99;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 1.4px;
  ${mq.pc} {
    font-size: 16px;
  }
  div {
    width: 18px;
  }
`

export const cssPolicyLink = css`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.2px;
`

export const cssFooterLink = css`
  justify-content: left;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.2px;
`

export const cssButtonImage = css`
  div {
    display: none;
    margin: 0;
    width: 0;
  }
  ${mq.pc} {
    div {
      display: flex;
      width: 12px;
      margin-left: 6px;
    }
  }
`

export const cssButtonImage2 = css`
  div {
    display: none;
    margin: 0;
    width: 0;
  }
  ${mq.pc} {
    position: relative;

    div {
      display: flex;
      position: absolute;
      right: 54px;
      width: 12px;
    }
  }
`


export const cssShadowGradientButton = css`
  ${cssGradientButton}
  ${cssShadowButton}
`
export const cssCampfireButton = css`
  ${cssLink}
  ${cssShadowButton}
`

export const cssThirdrdeconomyButton = css`
  ${css3rdeconomyColor}
  ${cssShadowButton}
  ${cssButtonImage}
`

export const cssThirdrdeconomyButton2 = css`
  ${css3rdeconomyColor}
  ${cssButtonImage2}
`
